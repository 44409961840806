import * as React from "react"
import Menu from "../../components/menu"
import Footer from "../../components/footer"
import clsx from "clsx"
import SuccesIcon from "../../images/success.svg"
import { title } from "../../styles"
import Seo from "../../components/seo"

// markup
const SuccesPage = (props) => {
  return (
    <div>
     <Seo>Contact Me</Seo> 
      <Menu/>
      <div className="max-w-screen-xl mx-5 xl:mx-auto">
        <div className="mt-12 md:pt-32 text-center pb-12">
          <SuccesIcon className="mx-auto"/>
          <h1 className={ clsx(title, "text-center", "my-4") }>
            Thank you for your message. 
          </h1>
          <div className="font-sans text-sm font-normal text-battleship-grey">
            <p>I'll get back to you as soon as possible.</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
     
  
  )
}


export default SuccesPage